

export default class Utils {
  
  static SortByDate = function(a, b) {
    var aTime = a.createdDateV2().time();
    var bTime = b.createdDateV2().time();
    var cTime = aTime - bTime;
    if (cTime) {
      //
    }
    if (aTime < bTime) {
      return -1;
    }
    if (aTime > bTime) {
      return 1;
    }
    return 0;
  }
  
}